"use strict"

Object.defineProperty(exports, "__esModule", {
  value: true
})
exports.discord = void 0
var discord = {
  viewBox: "0 0 24 24",
  children: [
    {
      name: "path",
      attribs: {
        d: "M18.6392 6.23962C20.8049 9.42499 21.8745 13.018 21.4747 17.1544C21.473 17.1719 21.4639 17.188 21.4495 17.1985C19.8094 18.4032 18.2204 19.1343 16.6536 19.6192C16.6414 19.6229 16.6284 19.6227 16.6163 19.6186C16.6042 19.6145 16.5937 19.6068 16.5863 19.5964C16.2243 19.0926 15.8954 18.5615 15.6073 18.0037C15.5908 17.9709 15.6059 17.9313 15.6399 17.9184C16.1622 17.7215 16.6589 17.4855 17.1367 17.2062C17.1743 17.1841 17.1767 17.1302 17.1419 17.1043C17.0405 17.029 16.9401 16.9499 16.844 16.8707C16.826 16.8561 16.8018 16.8532 16.7814 16.8631C13.6798 18.2958 10.2821 18.2958 7.14385 16.8631C7.12347 16.8539 7.09926 16.8571 7.08176 16.8714C6.98588 16.9506 6.8852 17.029 6.78476 17.1043C6.75 17.1302 6.75288 17.1841 6.79075 17.2062C7.26849 17.4803 7.76517 17.7215 8.28678 17.9193C8.32058 17.9323 8.33664 17.9709 8.31986 18.0037C8.03796 18.5622 7.70908 19.0933 7.34041 19.5971C7.32435 19.6175 7.29798 19.6269 7.27305 19.6192C5.71373 19.1343 4.12469 18.4032 2.48459 17.1985C2.47093 17.188 2.4611 17.1712 2.45966 17.1537C2.12551 13.5758 2.80653 9.95301 5.29256 6.2389C5.29855 6.22907 5.30766 6.2214 5.31821 6.21684C6.54145 5.65525 7.85195 5.24209 9.22165 5.00614C9.24658 5.0023 9.27151 5.01381 9.28446 5.03587C9.45369 5.33561 9.64714 5.71999 9.77802 6.03412C11.2218 5.81351 12.6881 5.81351 14.1621 6.03412C14.293 5.72671 14.4797 5.33561 14.6482 5.03587C14.6542 5.02493 14.6635 5.01616 14.6748 5.01082C14.6861 5.00548 14.6988 5.00384 14.711 5.00614C16.0814 5.24281 17.3919 5.65597 18.6142 6.21684C18.625 6.2214 18.6339 6.22907 18.6392 6.23962ZM10.512 13.0425C10.5271 11.9848 9.75621 11.1095 8.7885 11.1095C7.8287 11.1095 7.06522 11.9771 7.06522 13.0425C7.06522 14.1076 7.8438 14.9752 8.7885 14.9752C9.74854 14.9752 10.512 14.1076 10.512 13.0425ZM16.884 13.0425C16.8991 11.9848 16.1282 11.1095 15.1607 11.1095C14.2007 11.1095 13.4372 11.9771 13.4372 13.0425C13.4372 14.1076 14.2158 14.9752 15.1607 14.9752C16.1282 14.9752 16.884 14.1076 16.884 13.0425Z"
      }
    }
  ]
}
exports.discord = discord
