import React from "react"
import styled from "styled-components"
import Link from "next/link"
import NextImg from "@/common/components/NextImg"
import { themeGet } from "@styled-system/theme-get"
import fatf from "@/common/assets/image/compliance/fatf.png"
import ofac from "@/common/assets/image/compliance/ofac.png"
import { theme } from "@/common/Mixin"

export default function FooterBottom({ t, socialLinks }) {
  return (
    <Wrapper>
      <LeftWrapper>
        <Icon1Wrapper>
          <NextImg src={fatf} />
        </Icon1Wrapper>
        <Icon2Wrapper>
          <NextImg src={ofac} />
        </Icon2Wrapper>
        <p>{t("footer.bottom.desc")}</p>
      </LeftWrapper>
      <RightWrapper>
        <SocialList>
          {socialLinks.map(item => (
            <li className={item.name} key={`link-key${item.id}`}>
              <Link target="_blank" href={item.link} aria-label={item.name}>
                {item.icon}
              </Link>
            </li>
          ))}
        </SocialList>
        <p>© 2024 KryptoGO Co., Ltd.</p>
      </RightWrapper>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  padding: 40px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (${theme.xl}) {
    justify-content: center;
    padding: 0;
  }
`
const SocialList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }

  li {
    margin-right: 20px;
    margin-bottom: 20px;

    @media only screen and (max-width: 991px) {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: ${themeGet("colors.lightText", "#ffffff")};
      position: relative;
      transition: all 0.3s ease;

      i {
        position: relative;
        z-index: 1;

        svg {
          color: ${themeGet("colors.textFamily.disabled")};
          &:hover {
            color: ${themeGet("colors.primary")};
          }
          width: 24px;
          height: auto;
        }
      }

      &:hover {
        color: ${themeGet("colors.primary")};
      }
    }
  }
`
const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  p {
    font-size: 14px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.8);
  }
  @media (${theme.xl}) {
    align-items: center;
  }
`
const LeftWrapper = styled.div`
  display: flex;
  gap: 32px;
  p {
    font-size: 14px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.8);
    max-width: 260px;
  }
  @media (${theme.xl}) {
    display: none;
  }
`

const Icon1Wrapper = styled.div`
  width: 80px;
`
const Icon2Wrapper = styled.div`
  width: 80px;
  img {
    width: 100%;
  }
`
