import React from "react"
import styled from "styled-components"
import FooterButton from "./FooterButton"
import { NavMenuItemColumn } from "@/containers/common/menu.data.new"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"

interface FooterListCardProps {
  title: string
  data: NavMenuItemColumn[]
}

export default function FooterListCard({ title, data }: FooterListCardProps) {
  const { t } = useTranslation(["common"])
  const { locale } = useRouter()
  const flatData = data.map(({ groups }) => groups).flat()
  return (
    <Wrapper>
      <CardWrapper>
        <TitleWrapper>{t(title)}</TitleWrapper>
        <ListsWrapper className={data.length > 2 ? "more" : ""}>
          {flatData.map(({ label, items }) => (
            <List key={label ?? items[0].label}>
              {label && <FooterButton content={t(label)} type="label" />}
              {items.map(({ label, route, disabled, localizedRoute }) => {
                const href =
                  localizedRoute?.find(e => e.locale === locale)?.href || route
                return (
                  <FooterButton
                    disabled={disabled}
                    content={t(label)}
                    href={href}
                    key={route}
                  />
                )
              })}
            </List>
          ))}
        </ListsWrapper>
      </CardWrapper>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
`
const CardWrapper = styled.div``
const TitleWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 18px;
  padding-bottom: 16px;
  color: #fff;
  /* text-transform: lowercase; */
  /* text-transform: capitalize; */
`
const ListsWrapper = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  max-height: 340px;
  &.more {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
`
const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: start;
  gap: 4px;
`
