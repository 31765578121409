import logofooter from "@/common/assets/image/new/logo.svg"
import NextImg from "@/common/components/NextImg"
import Text from "@/common/components/Text"
import { theme } from "@/common/Mixin"
import NewsLetter from "@/containers/common/NewsLetter"
import { themeGet } from "@styled-system/theme-get"
import Link from "next/link"
import styled from "styled-components"

export default function FooterMiddle({ t, mail, phone, address, mapLink }) {
  return (
    <Wrapper>
      <LeftWrapper>
        <LogoWrapper>
          <Link href="/">
            <NextImg src={logofooter} alt="logo" />
          </Link>
        </LogoWrapper>
        <ListsWrapper>
          <a
            href={"mailto:contact@kryptogo.com"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text as="p" content={t(mail)} />
          </a>
        </ListsWrapper>
      </LeftWrapper>
      <RightWrapper>
        <NewsLetter t={t} />
      </RightWrapper>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  padding: 40px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  @media (${theme.xl}) {
    grid-template-columns: 1fr;
    justify-items: center;
    border-bottom: none;
    padding: 40px 0 24px;
  }
`
const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (${theme.xl}) {
    align-items: center;
  }
`

const LogoWrapper = styled.div`
  width: 147px;
  margin-bottom: 20px;
  a {
    width: 100%;
  }
  @media (${theme.xl}) {
    /* width: 200px; */
  }
`
const ListsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 16px;
  p {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 0px;
  }
  @media (${theme.xl}) {
    text-align: center;
    align-items: center;
  }
`

const RightWrapper = styled.div`
  @media (${theme.xl}) {
    display: none;
  }
`

const NewsletterWrapper = styled.div`
  /* margin-top: 16px; */
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: left;

  h3 {
    margin-bottom: 24px;
  }
  h5 {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.8);
  }
  a {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 400;
  }

  > div {
    width: 100%;
  }
  svg {
    margin-right: 10px;
  }

  > div > div {
    background: none !important;
    padding: 0 !important;
  }

  input {
    font-size: 16px;
    padding: 12px 16px;
    display: block;
    width: 100%;
    color: ${themeGet("colors.textColor", "#484848")};
    box-shadow: none;
    border-radius: 12px !important;
    box-sizing: border-box;
    border: 1px solid ${themeGet("colors.inactiveIcon", "#ebebeb")} !important;
    transition: border-color 0.2s ease;
    &:focus {
      outline: none;
      border-color: ${themeGet("colors.primary", "#028489")};
    }
  }

  button {
    padding: 4px 20px;
    cursor: pointer;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0);
    text-transform: uppercase !important;

    color: ${theme.color.primary};
    border: 1px solid ${theme.color.primary};

    border-radius: 8px;
    font-size: 18px;
    font-weight: 900;
    padding: 12px 28px;
    letter-spacing: 0.02em;
    min-width: 160px;
    min-height: 54px;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.3s ease;
    background-position: 0% 50%;

    @media (max-width: 640px) {
      min-width: 50px;
      margin-left: auto;
      margin-right: auto;
      min-height: 40px;
      padding: 12px 20px;
      font-size: 16px;
    }

    span.btn-text {
      font-weight: 900;
    }
    span.btn-icon {
      display: flex;
      > div {
        display: flex !important;
      }
    }

    &:not(:disabled):focus,
    &:not(:disabled):hover {
      outline: none;
      background: ${theme.color.primary};
      color: ${theme.color.background};
    }
  }
  position: relative;
  border-radius: 16px;
`
