import React from "react"
import styled from "styled-components"
import Link from "next/link"
import { theme } from "@/common/Mixin"

interface FooterButtonProps {
  disabled?: boolean
  content: string
  href?: string
  type?: string
}

export default function FooterButton({
  disabled,
  content,
  href,
  type
}: FooterButtonProps) {
  return (
    <>
      {type !== "label" && href ? (
        <Link href={href} style={{ pointerEvents: disabled ? "none" : "auto" }}>
          <Wrapper disabled={disabled} className={type}>
            {content}
          </Wrapper>
        </Link>
      ) : (
        <Wrapper disabled={disabled} className={type}>
          {content}
        </Wrapper>
      )}
    </>
  )
}
const Wrapper = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  padding: 4px 8px;
  border-radius: 4px;
  color: ${({ disabled }) =>
    disabled ? theme.color.textSecondary : "rgba(255, 255, 255, 0.8)"};
  &.label {
    cursor: default;
    background: ${theme.color.buttonHover};
    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  &:hover {
    color: ${theme.color.primary};
  }
`
