import React from "react"
import styled from "styled-components"
import FooterListCard from "@/common/components/_UI/FooterListCard"
import { theme } from "@/common/Mixin"
import { NavMenu } from "@/containers/common/menu.data.new"

interface FooterTopProps {
  data: NavMenu[]
}

export default function FooterTop({ data }: FooterTopProps) {
  return (
    <Wrapper>
      {data.map(({ id, category, itemColumn: items }) => (
        <FooterListCard key={id} title={category} data={items} />
      ))}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0;
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 1fr;
  gap: 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  @media (${theme.xxl}) {
    display: none;
  }
`
