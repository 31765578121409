import React from "react"

import { useTranslation } from "next-i18next"
import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"
import { footerData } from "@/common/data"
import { navMenu } from "@/containers/common/menu.data.new"
import { theme } from "@/common/Mixin"

import FooterTop from "./view/FooterTop"
import FooterBottom from "./view/FooterBottom"
import FooterMiddle from "./view/FooterMiddle"

const Footer: React.FC = () => {
  const { t } = useTranslation("common")
  const { mail, phone, address, mapLink, socialLinks } = footerData

  return (
    <FooterWrapper>
      <ContainerWrapper>
        <FooterTop data={navMenu} />
        <FooterMiddle
          t={t}
          mail={mail}
          phone={phone}
          address={address}
          mapLink={mapLink}
        />
        <FooterBottom t={t} socialLinks={socialLinks} />
      </ContainerWrapper>
    </FooterWrapper>
  )
}

export default Footer

const ContainerWrapper = styled.div`
  width: 100%;
`

const FooterWrapper = styled.footer`
  width: 100%;
  padding: 0 4vw;
  background: linear-gradient(
    to bottom,
    ${themeGet("colors.dark")}00 0,
    ${themeGet("colors.dark")} 150px
  );
  @media (${theme.lg}) {
    padding: 30px 0 40px;
  }
`

export const List = styled.ul`
  width: 100%;
`

export const ListItem = styled.li`
  a {
    display: inline-block;
    /* color: ${themeGet("colors.text")}; */
    font-size: 18px;
    margin-bottom: 12px;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: ${themeGet("colors.primary")};
      /* padding-left: 5px; */
    }
  }
`

export const LogoWrapper = styled.div`
  margin-bottom: 16px;
`
